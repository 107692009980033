import React from 'react'

export default function Footer() {

    return (
        <footer className='footer' style={{ display: 'block' }}>
            <div className='container h-100'>
                <div className='row py-4 h-100'>
                    <div className='col-md-6 col-12 h-100'>
                        <h2 className='text-white my-4'>Merkezimiz</h2>

                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d377.14314876304934!2d29.27365114696804!3d40.86868782393431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cadc867d29e2cd%3A0xf3f26843b3a793f0!2zw4dhbcOnZcWfbWUsIEhlbcWfZWhyaSBTb2thxJ_EsSwgMzQ4OTkgUGVuZGlrL8Swc3RhbmJ1bA!5e0!3m2!1str!2str!4v1704577656440!5m2!1str!2str"
                            style={{ border: '0', width: '100%', height: '510px' }}
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade" />

                    </div>
                    <div className='col-md-6 col-12 h-100'>
                        <h2 className='text-white my-4'>Şubemiz</h2>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3016.5598769885537!2d29.231187999999996!3d40.88153200000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDDCsDUyJzUzLjUiTiAyOcKwMTMnNTIuMyJF!5e0!3m2!1str!2str!4v1704557853223!5m2!1str!2str"
                            style={{ border: '0', width: '100%', height: '510px' }}
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                            title="Akıncı Et Şube"
                        />
                    </div>
                </div>
            </div>
        </footer>

    )
}

