import { useEffect, useState } from "react";

export default function Carousel({ images, textOne, textTwo, id }) {

    const [index, setIndex] = useState(0);

    return (
        <div id={id} class="carousel slide carousel-fade my-carousel my-5" >
            <div class="carousel-inner">
                {images.map((src, i) => (
                    <div key={i} className={`carousel-item ${i === index ? 'active' : ''}`}>
                        <img src={src} className="d-block w-100" alt="..." style={{ height: '700px', objectFit: 'cover' }} />
                        <div className="overlay"></div>
                        {id === "one" && <div className="overlay-text">{textOne[i]}</div>}
                        {id === "two" && <div className="overlay-text">{textTwo[i]}</div>}
                    </div>
                ))}
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target={"#" + id} data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target={"#" + id} data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    );
};