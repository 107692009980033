export const slides = [
    "assests/SLIDE_01.jpg", // Resim dosyalarını kendi dosya isimlerinizle değiştirin
    "assests/SLIDE_03.jpg",
];

export const navbarItems = [
    { href: '#hakkimizda', link: 'Hakkımızda' },
    { href: '#iletisim', link: 'İletişim' },
    { href: '#hizmetlerimiz', link: 'Hizmetlerimiz' },
    { href: '#subeler', link: 'Şubelerimiz' }
];

export const body1Data = [
    { text: 'Tavuk', icon: 'emojione-monotone:chicken' },
    { text: 'Taze Kesim', icon: 'fluent-emoji-high-contrast:cut-of-meat' },
    { text: 'Koyun/Kuzu', icon: 'game-icons:sheep' },
    { text: 'Dana', icon: 'fa6-solid:cow' },
    { text: 'Profesyonel Kesim', icon: 'mdi:axe' },
    { text: 'Hijyenik Kesim', icon: 'game-icons:gloves' }
]


export const body3Data = [
    { text: 'Doğal Lezzet', icon: 'game-icons:roast-chicken' },
    { text: 'Kalite Mührü', icon: 'teenyicons:certificate-outline' },
    { text: 'Ustaca Kesim', icon: 'lucide:axe' },
    { text: 'Paket Servis', icon: 'solar:box-outline' },
]

export const body4Data = [
    { text: 'Kalite ve Güvence: Her bir ürünümüz, en iyi kalite standartlarına uygun olarak işlenir ve titizlikle kontrol edilir. Size sadece en iyisi sunulur.', icon: 'ph:dot-fill' },
    { text: 'Hızlı ve Güvenilir Teslimat: Siparişleriniz özenle paketlenir ve en kısa sürede kapınıza kadar teslim edilir. Hızlı ve güvenilir alışveriş deneyimi için biz buradayız.', icon: 'ph:dot-fill' },
    { text: 'Taze ve Doğal: Et ürünlerimiz, doğal ve sağlıklı bir şekilde yetiştirilen hayvanlardan elde edilir. Taze ve doğal lezzeti sofralarınıza taşıyoruz.', icon: 'ph:dot-fill' },
    { text: ' Geniş Ürün Yelpazesi: Çeşitli et ürünleri arasından seçim yapabileceğiniz geniş bir ürün yelpazemiz bulunmaktadır. Her damak zevkine uygun lezzetleri keşfedin.', icon: 'ph:dot-fill' },
]

export const imagesOne = [
    'assests/akinci2.jpg',
    'assests/akinci7.jpg',
    'assests/akinci8.jpg',
    'assests/akincidepo.jpg',
    'assests/akincidepo1.jpg',
];

export const imagesTwo = [
    'assests/steakhouse1.jpg',
    'assests/steakhouse2.jpg',
    'assests/steakhouse3.jpg',
    'assests/steakhouse4.jpg',
];

export const textOne = [
    `Özenle Kesilmiş Etler`,
    `A Kalite Ürün`,
    `100% Doğal Ortamda Yetişen Hayvanlar`,
    `Profesyonel Kesim Ekibi`,
    `Hijyenik Paketleme`,
]

export const textTwo = [
    "Lezzet Şöleni: Izgara Spesiyaller!",
    "Mükemmel Tadlar, Sıcacık Sunumlar!",
    "Etin Zarafeti, Sofranızda Buluşuyor!",
    "Damak Çatlatan Et Lezzetleri!",
]