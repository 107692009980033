/*eslint-disable*/
import { Route, Routes } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import "./css/bootstrap-owerride.scss";


import Footer from './pages/Footer';
import Navbar from './pages/Navbar';
import Home from './pages/Home';


function App() {


  return (
    <div style={{ position: 'relative', overflowX: 'hidden', minHeight: '100vh' }}>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
