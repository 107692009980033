import React from 'react'
import Iconify from './Iconify'

export default function Whatsapp() {
    return (
        <div className='whatsapp'>
            <a href='https://wa.me/+905068918355'>
                <Iconify icon={"akar-icons:whatsapp-fill"}/>
            </a>
        </div>
    )
}
