/*eslint-disable*/
import React, { useEffect, useState } from 'react'
import Iconify from '../components/Iconify';
import Carousel from '../components/Carousel';
import Footer from '../pages/Footer';
import Whatsapp from '../components/Whatsapp';
import { body1Data, body3Data, body4Data, imagesOne, imagesTwo, navbarItems, slides, textOne, textTwo } from '../utils/datas';

export default function Home() {

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);


  return (
    <main>
      <header className='home'>
        <div className='home-begin'>
          <nav className='nav'>
            <div className="overlay">
              <div className='logo'> <img src={"assests/logo.png"} alt={`image`} className='img-fluid' /></div>
              <div className="my-navbar">
                {navbarItems.map((item, i) => (
                  <a href={item.href} key={i} className="nav-button">
                    {item.link}
                  </a>
                ))}
              </div>
            </div>
          </nav>

          <div className="slider-container">
            <div className="slider" style={{ transform: `translateX(${-currentIndex * 100}%)` }}>
              {slides.map((slide, index) => (
                <div className="slide" key={index}>
                  <img src={slide} alt={`Slide ${index + 1}`} />
                </div>
              ))}
            </div>
            <div className='slider-content'>
              {/* <p className='title my-1'>Organik Ürünler</p> */}
              <p className='title my-1'>Vizyonumuz tüketiciye sektörün en iyisini ulaştırmak</p>
              <h2 className='body-text mt-2'>Lezzetin, kalitenin ve güvenin buluştuğu bir adres: Akıncı Et Pendik! Biz,
                sofralarınızı en taze ve en sağlıklı et ürünleri ile donatmak için buradayız.
                Türkiye'nin dört bir yanından özenle seçilmiş, kaliteli ve doğal hayvansal ürünleri sizlere sunuyoruz.</h2>
              <p className='slogan my-3'>"Lezzetin Adı: Akıncı Et - Sofralarınıza Sağlık, Damaklarınıza Şölen!"</p>
              <hr className='box-cizgi' />
            </div>
          </div>
        </div>
      </header>

      <section className='body-1'>
        <div className='container'>
          <div className='icon-boxs'>
            {body1Data.map(val =>
              <div className='d-flex flex-column'>
                <div className='box'>
                  <div className='overlay'></div>
                  <Iconify className={"icon"} icon={val?.icon} />
                </div>
                <div className='box-text text-center mt-3'>
                  <p> {val.text} </p>
                </div>
              </div>
            )}
          </div>
          <hr className='box-cizgi' />
        </div>
      </section>

      <section className='body-2 my-5' id='hakkimizda'>
        <div className='row justify-content-center'>
          <div className='col-xl-4 col-lg-4 col-md-12 col-12 d-flex flex-column justify-content-center'>
            <p className='title'>Şirketimiz Hakkında</p>
            <h2 className='header my-2'>Sektörde mikro işletmeden makro işletmeye uzayan serüvenimiz</h2>
            <p className='description'>
              2005 yılından günümüze ulaşan tecrübesiyle et sektöründe hizmet veren Akıncı ET, kendini sürekli geliştiren; helal, temiz, lezzetli ürünlerinden taviz vermeyen ürün çeşitliliğini arttırarak hizmetine devam etmektedir.
              Kasap reyonlarımızda bulanan tüm ürünlerimiz, ‘TGK Et ve Et Ürünleri Tebliği' ne uygun olarak, doğal ortamlarda beslenen hayvanların etleri kullanılarak hazırlanmaktadır. Ürünlerimizin tümü Tarım Bakanlığı onaylıdır.
              Önceliğimiz; sosyal sorumluluğumuzu layığıyla yerine getirmeye devam etmektir. Bizi tercih eden misafirlerimize hak ettikleri kalite ve hizmeti devam ettirmektir. Helal, temiz ve lezzetli ürünlerimizin devamlılığını sağlamaktır. Nesillerimizin sağlıklı yetişmesi şuuruyla hareket etmek, ürün çeşitliliğimizi arttırıp tüm insanımıza ulaşmasını sağlamaktır.
              Hedefimiz; sektörümüze değer katmak ve gelişmesine gayret etmektir. Helal, temiz ve lezzetli ürünlerimizi Kasap ve Şubemizde insanlara en temiz bir şekilde sunmaktır.
            </p>
          </div>
          <div className='col-xl-6 col-lg-6 col-md-12 col-12 ms-5 d-flex justify-content-center'>
            <img src='assests/meat-new.jpg' className='img-fluid' />
          </div>
        </div>
      </section>

      <section className='body-3'>
        <div className='overlay'></div>
        <div className='overlay-content'>
          {body3Data.map(val =>
            <div className='box'>
              <div className='icon text-center'>
                <Iconify icon={val.icon} />
              </div>
              <div className='text text-center'>
                <p>{val.text}</p>
              </div>
            </div>
          )}
        </div>
      </section>

      <section className='body-4' id='hizmetlerimiz'>
        <div className='container'>
          <div className='row my-3'>
            <div className='col-md-6 col-12'>
              <div className='p-2 h-100 d-flex flex-column justify-content-center'>
                <h2 className='header'>Neden Biz?</h2>
                {body4Data.map(val =>
                  <div className='card-content'>
                    <div className='card-icon'>
                      <Iconify icon={val.icon} />
                    </div>
                    <p className='card-text'> {val.text}</p>
                  </div>
                )}
              </div>
            </div>
            <div className='col-md-6 col-12'>
              <div className='card p-2'>
                <img src='assests/akinci1.jpg' className='img-fluid' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='body-5 my-5' id='subeler'>
        <div className='container'>
          <div className='header my-4'>Şubelerimiz</div>
          <div className='row'>
            <div className='col-md-6'>
              <h2 className='text-1 my-2 text-center'>Depo</h2>
              <Carousel id={"one"} images={imagesOne} textOne={textOne} />
            </div>
            <div className='col-md-6'>
              <h2 className='text-1 my-2 text-center'>Pendik Şubemiz</h2>
              <Carousel id={"two"} images={imagesTwo} textTwo={textTwo} />
            </div>
          </div>
        </div>
      </section>

      <section className='body-6 my-5' id='iletisim'>
        <div className='row'>
          <div className='col-md-5'>
            <img src='assests/blog_03-755x521.jpg' className='img-fluid' />
          </div>
          <div className='col-md-7 px-2'>
            <div className='d-flex justify-content-between align-items-center pe-3'>
              <h2 className='contact-us ms-3'>Bizimle İletişime Geçin</h2>
              <img src='assests/logo.png' style={{ width: '180px', height: '180px' }} />
            </div>
            {/* <h2 className='name'>Ali Akıncı</h2>
            <h2 className='phone'>0532 625 81 77</h2> */}
            <br />
            <h2 className='name'>Merkez:</h2>
            <h2 className='addres' style={{ lineHeight: '40px' }}>Çamçeşme mahallesi hemşehri sokak No:3/2 Pendik/İstanbul</h2>
            <h2 className='phone'>0216 397 65 95</h2>
            <br />
            <h2 className='name'>Şube:</h2>
            <h2 className='addres'>Yeni Mah. Begonya Sk. No:3/B Pendik/İstanbul</h2>
            <h2 className='phone mt-2'>0216 375 04 20</h2>
            <br />
            <h2 className='name'>Mail:</h2>
            <h2 className='mail'>akincibeyetltd@gmail.com</h2>
            <br />
          </div>
        </div>
      </section>
      <Whatsapp />

      <Footer />
    </main >

  )
}
